<template>
    <div class="">
        <modal-tool-transfer :thisModal="modalNewToolTransfer" modalId="modalNewToolTransfer" title="Transfer" :editTool="editTool" @confirmed="confirmedToolTransfer" />
        <modal-tool-transfer-confirm :thisModal="modalNewToolTransferConfirm" modalId="modalNewToolTransferConfirm" title="Transfer" :toolTransfer="selectedToolTransfer" @confirmed="confirmedToolTransfer" />


        <!-- TOOOLS -->

        <h2>Moji alati</h2>

        <!-- <tool-types-buttons class="mb-3" :selectedToolType.sync="toolType" @changed="changedToolType()" /> -->

        <div class="mt-3 d-flex justify-content-between">
            
            <div class="col-md-4">
                <h5>Vrednost alata: {{sumValueTools}} dinara</h5>
            </div>

            <div class="btn-group" role="group" aria-label="Vehicles View">
                <input type="radio" class="btn-check" name="vehicle-view" id="vehicle-view1" value="GRID" v-model="toolsView" autocomplete="off" checked>
                <label class="btn btn-outline-primary" for="vehicle-view1"><font-awesome-icon size="2x" icon="th" /></label>

                <input type="radio" class="btn-check" name="vehicle-view" id="vehicle-view2" value="TABLE" v-model="toolsView" autocomplete="off">
                <label class="btn btn-outline-primary" for="vehicle-view2"><font-awesome-icon size="2x" icon="table" /></label>
            </div>
        </div>


        <div class="row" v-if="toolsView === 'GRID'">

            <h5 v-if="allTools.length === 0" class="m-5">Nemate zaduženog alata</h5>

            <div v-for="tool in allTools" :key="tool.id" class="tool-item-grid col-lg-3 col-md-4 col-sm-6">
                <div class="tool-item-box">

                    <div class="tool-image-box">
                        <router-link class="position-relative w-100 h-100 d-flex align-items-center" :to="{ name: 'tool-profile', params: { toolId: tool.id }}" >
                            <img class="tool-image-back" :src="tool.image_url" alt="">
                            <img class="tool-image" :src="tool.image_url" alt="">
                        </router-link>
                    </div>
                    <div class="tool-info">
                        <div class="info-top">
                            <router-link class="tool-name" :to="{ name: 'tool-profile', params: { toolId: tool.id }}" >
                                <h3>{{tool.name}} <em v-if="tool.identification">{{tool.identification}}</em></h3>
                            </router-link>

                            <small>{{tool.manufacturer}}</small>
                        </div>
                        <div class="info-bottom">
                            <button @click="showToolTransferModal(tool)" class="btn btn-secondary btn-small"><font-awesome-icon icon="exchange-alt" /> Transfer</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="toolsView === 'TABLE'">
            <ejs-grid
                ref="allTools"
                :dataSource="toolsAndPending" 
                class="my-tools-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :allowResizing='true'
                :allowTextWrap='true'
                :queryCellInfo='customiseCell'
                :recordClick="toolClick"


                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    
                    <e-column field='image_url' :allowFiltering='false' class="image-column" :allowSorting='false' :showColumnMenu='false' headerText='Slika' width='120' textAlign='Center' :template="toolsImage"></e-column>
                    <e-column field='name' headerText='Naziv' width='120' textAlign='Left' :template="toolsNameTemplate"></e-column>
                    <e-column field='manufacturer' headerText='Proizvodjač' width='120' textAlign='Left'></e-column>
                    <e-column field='type_obj.name' headerText='Tip' width='120' textAlign='Left'></e-column>
                    <e-column field='value' headerText='Vrednost' width='120' textAlign='Right'></e-column>

                    <e-column field='holder_display' headerText='Lokacija' width='120' textAlign='Left'></e-column>

                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>

                    <e-column headerText='' :visible='true' textAlign='Right' :template="toolsCMD"></e-column>

                </e-columns>
            </ejs-grid>    
        </div>

    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import RequestStatus from '@/components/RequestStatus.vue';
    // import { Modal } from 'bootstrap';
    import UtilService from '@/service/UtilService.js';

    import ToolsService from '@/service/ToolsService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    // import ToolTypesButtons from '@/components/tools/ToolTypesButtons.vue';
    import ModalToolTransfer from '@/components/modals/tools/ModalToolTransfer.vue'
    import { Modal } from 'bootstrap'
    import ToolsCMD from '@/components/grid-templates/ToolsCMD.vue';
    import ModalToolTransferConfirm from '@/components/modals/tools/ModalToolTransferConfirm.vue';


    export default {
        components: {
            ModalToolTransfer,
            ModalToolTransferConfirm,
        },
  
       data() {
     
            return {
                modalNewTool: null,
                modalNewToolTransfer: null,
                modalNewToolTransferConfirm: null,
                modalQR: null,

                allTools: [],

                toolType: undefined,
                warehouse: undefined,
                editTool: undefined,
                selectedToolTransfer: undefined,

                toolsView: "TABLE",


                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },

                toolsImage: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "IMAGE"
                            },   
                        }
                    };
                },
                
                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "NAME"
                            },   
                        }
                    };
                },

                toolsCMD: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "CMD"
                            },
                            
                        }

                    };
                },
                
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },

        computed: {
            ...mapState([
                'user',
                'awaitingToolTransfers',
            ]),

            toolbar() {
                if (this.user.role == "ADMIN") return this.toolbarAdmin;
                return null;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },


            sumValueTools() {
                return this.allTools.reduce((a, b) => a + Number(b['value'] || 0.0), 0);
            },

            toolsAndPending() {
                let toolsAndPending = [...this.allTools];

                this.awaitingToolTransfers.forEach(x => {
                    if (x.to_user !== this.user.id) return;

                    let transfer = UtilService.deepClone(x);
                    let tool = transfer.tool_obj;
                    tool.transfer = transfer;
                    tool.transfer_pending = true;
                    

                    toolsAndPending.unshift(tool);
                })
                

                return toolsAndPending
            }
        },


        

        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },
            // async loadAllTransports() {
            //     this.loading = true;
            //     this.error = null;

            //     await this.$store.dispatch('loadAllTransportVehicles', this.workOrderForm).catch((error) => {
            //         this.error = error;
            //     });

            //     this.loading = false;
            // },

            changedToolType() {
                this.loadData();
            },

            changedWarehouse() {
                this.loadData();
            },

            customiseCell(args) {
                if (args.data.transfer_pending) {
                    args.cell.classList.add('pending-transfer');
                }
            },

            loadMyTools() {
                let query = {
                    worker: this.user.id,
                }

                ToolsService.getAllTools(query).then(async (result)=>{
                    this.allTools = ToolsService.prepareToolsData(result.data);
                });
            },

            async loadAllToolTypes() {
                await this.$store.dispatch('getAllToolTypes')
            },

            loadData() {
                this.loadAllToolTypes
                this.loadMyTools();
            },

            toolDBClick(event) {
                this.showEditToolModal(event.rowData);
            },

            showAddModal() {
                this.editTool = undefined;
                this.modalNewTool.show();
            },

            showEditToolModal(selected) {
                this.editTool = selected;
                this.modalNewTool.show();
            },

            showQRModal() {
                this.modalQR.show();
            },

            showToolTransferModal(selected) {
                this.editTool = selected;
                this.modalNewToolTransfer.show();
            },

            toolClick(event) {
                if (event.rowData?.transfer_pending)
                    this.showToolTransferConfirmModal(event.rowData);
            },
            showToolTransferConfirmModal(selected) {
                this.selectedToolTransfer = selected.transfer;
                this.modalNewToolTransferConfirm.show();
            },

            confirmedTool() {
                this.loadData();
            },

            confirmedToolTransfer() {
                this.loadData();
                this.$store.dispatch('loadAwaitingToolTransfers');

            },

        },


        created() {
        },

        mounted() {
            this.loadData();
            this.modalNewTool = new Modal(document.getElementById('modalNewTool'), {backdrop: 'static', keyboard: false});
            this.modalNewToolTransfer = new Modal(document.getElementById('modalNewToolTransfer'), {backdrop: 'static', keyboard: false});
            this.modalQR = new Modal(document.getElementById('modalQR'));
            this.modalNewToolTransferConfirm = new Modal(document.getElementById('modalNewToolTransferConfirm'));

        }


    }
</script>

<style>
    .my-tools-grid table tr .pending-transfer {
        background: #ffff00 !important;
        cursor: pointer;
        /* color: #666666 !important; */
    }
</style>

<style scoped>
    .tool-item-grid {
        margin-bottom: 2em;
    }

    .tool-item-grid .tool-item-box {
        height: 100%;
        display: flex;
        background: #fefefe;
        box-shadow: rgba(0, 0, 0, 0.3) 0 0 15px;
        position: relative;
    }


    .tool-item-box .tool-image-box {
        width: 30%;
        display: flex;
        align-items: center;
        position: relative;
    }

    .tool-item-box .tool-image-box .tool-image {
        width: 100%;
        object-fit: cover;
        height: 100%;
        transition: 0.5s ease all;
        z-index: 10;
    }


    .tool-item-box .tool-image-box .tool-image-back {
        position: absolute;
        width: 100%;
        z-index: 5;
    }

    .tool-item-box:hover .tool-image-box .tool-image {
        height: 50%;
    }



    .tool-info {
        padding: 1em;
        width: 70%;
        font-size: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .tool-info .tool-name {
        text-decoration: none;
        color: #444;
    }

    .tool-info .tool-name h3 {
        font-weight: bold;
        font-size: 1.25rem;
    }

</style>
